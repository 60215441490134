import { Col, Row, Space, Tag, Typography } from "antd";
import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import CustomIconButton from "../../../../../../components/UI/IconButton/IconButton";
import DeleteIcon from "../../../../../../components/UI/icons/DeleteIcon";
import DropdownIcon from "../../../../../../components/UI/icons/DropdownIcon";
import CustomAvatar from "../../../../../../components/comon/CustomAvatar";

const ReviewCards = ({
  supervisor,
  toggleshowDeleteReview,
  setSelectedItems,
}) => {
  const [expandIconPosition, setExpandIconPosition] = useState(false);
  const onExpand = () => {
    setExpandIconPosition(!expandIconPosition);
  };

  const date = useMemo(() => {
    if (!!supervisor) {
      return supervisor?.reviews[0]?.createdAt;
    }
  }, [supervisor]);

  return (
    <>
      {" "}
      <div className="header-section cursor-pointer" onClick={onExpand}>
        <Space className="staff-info">
          <CustomAvatar
            src={supervisor?.image}
            alt={supervisor?.name}
            size="large"
          />

          <div className="staff-name">
            {" "}
            <p>{supervisor?.name}</p>
            <span>{supervisor?.roleTitle}</span>
          </div>
        </Space>

        <Tag className="staff-tag">
          Date Added: {dayjs(date).format("DD MMM YYYY")}{" "}
          <span>({dayjs(date).format("ddd")})</span>
        </Tag>
        <Space className="rgt-btns">
          <CustomIconButton
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
              toggleshowDeleteReview();
              setSelectedItems(supervisor);
            }}
          />
          <CustomIconButton icon={<DropdownIcon />} />
        </Space>
      </div>
      {expandIconPosition ? (
        <div className="body-section">
          {" "}
          <Row gutter={[16, 16]}>
            {supervisor?.reviews?.map((review, index) => (
              <Col span={12} key={index}>
                <div className="perform-question-card">
                  <Space direction="horizontal" style={{ marginBottom: 8 }}>
                    <span>{index + 1}</span>
                    <Typography.Title level={5}>
                      {review?.question}
                    </Typography.Title>
                  </Space>
                  <p>{review?.answer}</p>
                </div>
              </Col>
            ))}
          </Row>{" "}
        </div>
      ) : null}{" "}
    </>
  );
};

export default ReviewCards;
